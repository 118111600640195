const transitionStyle = '0.5s height ease-in-out';
const Transition = {
  // 进入前 设置el元素的transition,高度0
  beforeEnter(el) {
    el.style.transition = transitionStyle;
    el.style.height = 0;
  },
  // 进入中
  enter(el) {
    // 获取元素显示的高度
    if (el.scrollHeight !== 0) {
      el.style.height = `${el.scrollHeight}px`;
    } else {
      //没有高度就不显示
      el.style.height = '';
    }
    el.style.overflow = 'hidden';
  },
  // 进入结束
  afterEnter(el) {
    // 清除过度样式
    el.style.transition = '';
    el.style.height = '';
  },

  // 离开前
  beforeLeave(el) {
    el.style.height = `${el.scrollHeight}px`;
    el.style.overflow = 'hidden';
  },
  // 离开中
  leave(el) {
    if (el.scrollHeight !== 0) {
      el.style.transition = transitionStyle;
      el.style.height = 0;
    }
  },
  // 离开结束
  afterLeave(el) {
    el.style.transition = '';
    el.style.height = '';
  },
};

export default {
  functional: true, //指定函数组件
  render(h, { children }) {
    return h('transition', { on: Transition }, children);
  },
};
