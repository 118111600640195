<template>
  <div class="layout_box">
    <!-- 网页头部导航 -->
    <head-nav
      ref="nav"
      @is_backTop="is_backTop = $event"
      :product_nav="product_nav"
      :application_nav="application_nav"
    ></head-nav>
    <router-view :product_nav="product_nav" :application_nav="application_nav">
    </router-view>

    <!-- 返回顶部 -->
    <div class="back_top" v-show="is_backTop" @click="backTop">
      <i class="icon-changyong_shouqi iconfont top_icon"></i>
    </div>
    <!-- 网页底部导航 -->
    <foot-nav></foot-nav>
  </div>
</template>

<script>
import HeadNav from "@/components/head-nav.vue";
import FootNav from "@/components/foot-nav.vue";

export default {
  name: "Layout",
  data() {
    return {
      is_backTop: false, //返回顶部状态
      //产品中心导航数据
      product_nav: [],
      //解决方案导航数据
      application_nav: [],
    };
  },
  components: { HeadNav, FootNav },
  methods: {
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现过度滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    load_nav() {
      const { home } = require("@/assets/data/data.json");
      this.product_nav = home.system_list;
      this.application_nav = home.application_list;
    },
  },
  created() {
    this.load_nav();
  },
};
</script>
<style lang="less" scoped>
.layout_box {
  position: relative;
  padding-top: 200px;
  // min-height: 100vh;
  .back_top {
    position: fixed;
    right: 10px;
    bottom: 40px;
    width: 120px;
    height: 120px;
    background-color: #e0e0e070;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    .top_icon {
      font-size: 50px;
      color: #6e6e73;
    }
  }
}
header {
  position: absolute;
  top: 0;
}

// footer {
//   position: absolute;
//   bottom: 0;
// }
</style>