<template>
  <footer>
    <div class="design">
      <div class="info">
        <h2>项目方案设计</h2>
        <p>
          深入用户现场业务流程,为客户提供需求评估、业务分析、系统应用和可行性分析等解决方案。助力企业信息化建设精准定位、为企业项目建设提供方案指导。
        </p>
      </div>
    </div>
    <div class="foot">
      <div class="icon iconfont icon-kefu"></div>
      <p>服务热线</p>
      <p class="time">[ 9:00 - 18:00 ]</p>
      <div class="phone">
        <div class="num">
          <!-- <a href="tel:18019568711">180 1956 8711</a> -->
          <!-- <a href="tel:17755102901">177 5510 2901</a> -->
          <a href="tel:0551-63829863">0551-63829863</a>
        </div>
      </div>
      <p class="email">服务邮箱 <span>xyl@hfgw.info</span></p>
      <div class="more_contact">
        <a href="javascript:;" class="iconfont icon-QQ"></a>
        <a href="javascript:;" class="iconfont icon-weixin"></a>
        <a href="javascript:;" class="iconfont icon-youxiang"></a>
      </div>
      <div class="copyright">
        <p class="top"><span>版权所有 </span>合肥广为数盈信息科技有限公司</p>
        <p>
          <a class="beian" href="http://beian.miit.gov.cn/" target="_blank"
            >皖ICP备2021014350号-2</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footnav",
};
</script>

<style lang="less" scoped>
footer {
  .design {
    position: relative;
    width: 100%;
    height: 400px;
    background: url("~@/assets/img/design.png") no-repeat center top;
    background-size: cover;
    user-select: none;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .info {
      font-size: 30px;
      color: #fff;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      padding: 50px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      h2 {
        font-weight: 500;
      }
      p {
        text-align: left;
        line-height: 1.6;
      }
    }
  }
  .foot {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #262626;
    color: #fff;
    font-size: 25px;
    .icon {
      font-size: 130px;
      color: #4fbdf6;
    }
    .num {
      a {
        display: block;
        font-size: 35px;
        color: #4fbdf6;
      }
    }
    .more_contact {
      width: 100%;
      padding: 0 230px;
      display: flex;
      justify-content: space-around;
      a {
        font-size: 50px;
        color: #fff;
      }
    }
    .copyright {
      text-align: center;

      .top {
        font-size: 25px;
      }
      .beian {
        font-size: 20px;
        color: #fff;
      }
    }

    .email {
      font-size: 25px;
      color: #fff;
      span {
        font-size: 30px;
      }
    }
  }
}
</style>