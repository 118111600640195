<template>
  <header>
    <!-- 移除颜色变化:class="{ head_bgc: icon_toogle }" -->
    <div class="head_top" ref="head_height">
      <!-- 左侧logo -->
      <div class="logo" @click="$router.push({ name: 'Home' })"></div>
      <!-- 右侧图标 -->
      <div
        class="iconfont nav_icon"
        :class="[icon_toogle ? 'icon-shanchu' : 'icon-xuanxiang']"
        @click.stop="icon_toogle = !icon_toogle"
      ></div>
    </div>

    <!-- 申请试用 -->
    <div
      class="apply"
      @click.stop="apply"
      :class="{ apply_fixed: apply_is_fixed }"
    >
      <p><span>点击享受免费试用: </span>专业团队支持</p>
      <p>追溯一站式解决方案指导等服务</p>
    </div>

    <!-- 导航列表 -->

    <transition>
      <div
        class="nav_list animate__faster"
        v-show="icon_toogle"
        :class="[
          icon_toogle
            ? 'animate__animated animate__fadeInDown'
            : 'animate__animated animate__fadeOutUp',
        ]"
      >
        <ul>
          <li @click="$router.push({ name: 'Home' })">首页</li>
          <li @click="$router.push({ name: 'About' })">关于我们</li>

          <li>
            <div class="nav_title" @click.stop="ishow_sec_nav(0)">产品中心</div>
            <!-- 使用高度过渡效果工具组件 -->
            <my-transition>
              <div class="sub_title" v-show="active_nav_index == 0">
                <!-- 跳转传参 -->
                <router-link
                  v-for="(item, i) in product_nav"
                  :key="i"
                  @click.native="ishow_sec_nav(0)"
                  :to="{
                    path: item.router_path,
                    query: {
                      title: item.banner_title,
                      desc: item.banner_desc,
                    },
                  }"
                >
                  {{ item.system_title }}
                </router-link>
              </div>
            </my-transition>
          </li>

          <li>
            <div class="nav_title" @click.stop="ishow_sec_nav(1)">解决方案</div>
            <!-- 使用高度过渡效果工具组件 -->
            <my-transition>
              <div class="sub_title" v-show="active_nav_index == 1">
                <!-- 跳转传参 -->
                <router-link
                  v-for="(item, i) in application_nav"
                  :key="i"
                  @click.native="ishow_sec_nav(1)"
                  :to="{
                    path: item.router_path,
                    query: {
                      title: item.banner_title,
                      desc: item.banner_desc,
                    },
                  }"
                >
                  {{ item.title_1 }}
                </router-link>
              </div>
            </my-transition>
          </li>

          <li @click="$router.push({ name: 'Contact' })">联系我们</li>
          <li @click="$router.push({ name: 'Home' })">广为数盈云</li>
        </ul>
      </div>
    </transition>
  </header>
</template>

<script>
import myTransition from "@/utils/transition.js";
export default {
  name: "headnav",
  props: {
    //产品中心导航数据
    product_nav: {
      type: Array,
      required: true,
    },
    //解决方案导航数据
    application_nav: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      icon_toogle: false, //图标状态
      apply_is_fixed: false, //申请试用模块 位置状态
      active_nav_index: null, //被点击的二级导航
    };
  },
  components: {
    "my-transition": myTransition,
  },
  methods: {
    // 二级导航显示状态处理
    ishow_sec_nav(n) {
      if (this.active_nav_index == n) {
        this.active_nav_index = null;
      } else {
        this.active_nav_index = n;
      }
    },
    clickOther() {
      if (this.icon_toogle) {
        this.icon_toogle = false;
      }
    },
    apply() {
      window.location.href = "https://666.shiaj.com/Company/RegistCompany";
    },
    scrollToTop() {
      // 被卷曲的距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 元素的高度
      var dom_height = this.$refs.head_height.offsetHeight;

      // console.log(scrollTop, dom_height);
      if (scrollTop >= dom_height) {
        this.apply_is_fixed = true;
        this.$emit("is_backTop", true);
      } else {
        this.apply_is_fixed = false;
        this.$emit("is_backTop", false);
      }
    },
  },

  mounted() {
    document.body.addEventListener("click", this.clickOther, false);
    window.addEventListener("scroll", this.scrollToTop);
    // window.addEventListener("scroll", () => {
    //   clearTimeout(this.timer);
    //   this.timer = setTimeout(() => {
    //     this.scrollToTop();
    //   }, 50);
    // });
  },
};
</script>

<style lang="less" scoped>
header {
  position: relative;
  width: 100%;

  .head_top {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #e0e0e0;
    background-color: rgba(0, 0, 0, 0.9);
    user-select: none;
    border-bottom: 2px solid #4fbdf6;

    transition: background-color 1s;
  }
  .logo {
    height: 100%;
    width: 350px;
    background: url("~@/assets/img/logo.png") no-repeat center / 100%;
    filter: drop-shadow(black 2px 1px 2px);
  }

  .nav_icon {
    width: 130px;
    height: 100px;
    font-size: 65px;
    color: #fff;
    text-align: center;
  }

  .nav_list {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    z-index: 9999;

    li {
      // background-color: rgba(66, 66, 69, 1);
      // background-color: #4fbdf6;
      background-color: rgba(0, 0, 0, 0.9);
      box-shadow: 0 0 3px rgba(255 255 255 / 0.9);
      width: 100%;
      padding: 15px 40px;
      font-size: 40px;
      color: #d6d6d6;
      // text-shadow: black 4px 4px 4px;

      // &::after {
      //   font-family: "iconfont";
      //   content: "\e6ad";
      // }

      .nav_title {
        // border-bottom: 2px solid #4fbdf6;
      }
      .sub_title {
        a {
          display: block;
          font-size: 30px;
          color: #d6d6d6;
          line-height: 1.8;
        }
      }
    }
  }
  .apply {
    position: relative;
    // top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100px;
    line-height: 100px;
    padding: 0 100px;
    background-color: #e0e0e0;
    background-color: rgba(0, 0, 0, 0.9);
    text-align: center;
    color: #6e6e73;
    color: #fff;
    font-size: 30px;
    z-index: 999;
    // // 背景毛玻璃效果
    // &::after {
    //   content: "11111";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   filter: blur(20px);
    //   margin: -30px;
    //   z-index: -1;
    // }

    p {
      line-height: 1.5;
    }
    span {
      color: #4fbdf6;
    }
  }
}
// 头部颜色
.head_bgc {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

// 切换试用模块固定定位
.apply_fixed {
  position: fixed !important;
  top: 0;
}
</style>