import { render, staticRenderFns } from "./foot-nav.vue?vue&type=template&id=642f0b44&scoped=true&"
import script from "./foot-nav.vue?vue&type=script&lang=js&"
export * from "./foot-nav.vue?vue&type=script&lang=js&"
import style0 from "./foot-nav.vue?vue&type=style&index=0&id=642f0b44&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642f0b44",
  null
  
)

export default component.exports